import { createClient } from 'next-sanity';
import { config } from './config';

// Set up the client for fetching data in the getProps page functions
export const sanityClient = createClient(config);

//TODO remove when we're done with the migration
const newClient = createClient({
  ...config,
  dataset: 'lilcat-new',
});

const lildogClient = createClient({
  ...config,
  dataset: 'lildog-new',
});

// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
  ...config,
  useCdn: false,
  token: process.env.SANITY_PREVIEW_TOKEN,
});

// Helper function for easily switching between normal client and preview client
// TODO remove useNewDataset when we're done with the migration
export const getClient = (usePreview, useNewDataset = false) => {
  return usePreview ? previewClient : useNewDataset ? newClient : sanityClient;
};

export const getLildogClient = (usePreview) => {
  return usePreview ? previewClient : lildogClient;
};
