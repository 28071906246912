import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const Faqs = ({ faqs }) => {
  return (
    <div className="text-primary-1">
      {faqs.map((faq, index) => (
        <Disclosure as="div" key={faq.question} className="pt-6">
          {({ open }) => (
            <>
              <dt className="text-lg">
                <Disclosure.Button className="text-left w-full flex justify-between items-start">
                  <span className="'text-blackberry text-h6-desktop font-objektiv font-bold leading-9">
                    {`${index + 1}. ${faq.question}`}
                  </span>
                  <span className="ml-6 h-7 flex items-center">
                    <ChevronDownIcon
                      className={classNames(
                        open ? '-rotate-180' : 'rotate-0',
                        'h-6 w-6 transform text-blackberry'
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                {faq.answer.split('●').map((line, index) => (
                  <div key={index}>
                    {index === 0 && (
                      <span>
                        <p className=" text-blackberry leading-6 text-h6 font-normal font-objektiv">
                          {line}
                        </p>
                      </span>
                    )}
                    <ul className="font-normal font-objektiv text-p-md leading-p-md space-y-4  text-blackberry lg:text-p-md-desktop lg:leading-p-desktop-md lg:ml-5">
                      {index !== 0 && (
                        <li className=" text-h6 leading-6 pt-2">{line}</li>
                      )}
                    </ul>
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default Faqs;
